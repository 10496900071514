/**
 * @fileoverview React Component for header navigation bar that will display on all pages of TD Collection.
 */

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import {
  makeStyles,
  Toolbar,
  IconButton,
  SvgIcon,
  Paper,
  InputBase,
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  toolBar: {
    flexGrow: 1,
    justifyContent:'space-between',
  },
  logo: {
    justifySelf: 'flex-start',
  },
  searchField: {
    margin: '1em',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '400px',
    flexGrow: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 4,
  },
}));

/**
 * A header navigation bar with Site Title and Login/Logout Button
 *
 * @param {boolean} seachbar - When `true`, includes a searchbar for filtering tokens.
 */
export default function Navigation(props) {
  const classes = useStyles();

  const [searchbar] = useState(() => {
    if(typeof props.searchbar !== 'undefined'){
      return props.searchbar;
    }
    return false;
  });

  const [query, setQuery] = useState('');
  const tokenQuery = useRef('');

  // Updates when a new query value is finished writing to state
  useEffect(
    () => {
      console.log(`Query: ${query}`)
    }, [query]
  );

  const handleOnChange = (event) => {
    console.log(tokenQuery.current.value);
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setQuery(() => tokenQuery.current.value)
    alert(`In a future update, tokens would be filtered for the value "${tokenQuery.current.value}"`);
  }

  return (
    <Toolbar className={classes.toolBar} key="navbar">
      <Link to="/">
        {/* TDC Logo */}
        <SvgIcon className={classes.logo} fontSize="large" viewBox="-0.5 -0.5 125 155">
          <path d="M 32 24.5 L 53.36 12.04 Q 62 7 70.64 12.04 L 113.36 36.96 Q 122 42 113.36 47.04 L 70.64 71.96 Q 62 77 53.36 71.96 L 10.64 47.04 Q 2 42 10.64 36.96 Z" fill="#37464f" stroke="#37464f" strokeWidth="5" strokeMiterlimit="10" pointerEvents="none"/>
          <ellipse cx="62" cy="42" rx="40" ry="40" fill="#ffb300" stroke="#ffb300" strokeWidth="5" pointerEvents="none"/>
          <path d="M 15.5 127 L -13 127 Q -23 127 -18.18 118.24 L 5.18 75.76 Q 10 67 20 67 L 77 67 Q 87 67 82.18 75.76 L 58.82 118.24 Q 54 127 44 127 Z" fill="#62717b" stroke="#37464f" strokeWidth="5" strokeMiterlimit="10" transform="rotate(90,32,97)" pointerEvents="none"/>
          <path d="M 76.11 127 L 47 127 Q 37 127 41.68 118.16 L 64.1 75.84 Q 68.78 67 78.78 67 L 137 67 Q 147 67 142.32 75.84 L 119.9 118.16 Q 115.22 127 105.22 127 Z" fill="#62717b" stroke="#37464f" strokeWidth="5" strokeMiterlimit="10" transform="translate(0,97)scale(1,-1)translate(0,-97)rotate(-90,92,97)" pointerEvents="none"/>
        </SvgIcon>
      </Link>
      {searchbar &&
        <Paper component="form" className={classes.searchField}>
          <InputBase
            className={classes.input}
            inputRef={tokenQuery}
            placeholder="Search for tokens"
            inputProps={{ 'aria-label': 'search for tokens' }}
            onChange={(event) => handleOnChange(event)}
          />
          <IconButton
            type="submit"
            aria-label="search"
            onClick={(event) => handleSearchSubmit(event)}
            className={classes.iconButton}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      }
      <div>
        <IconButton edge="end" color="primary" aria-label="menu">
          <MoreVertIcon />
        </IconButton>
      </div>
    </Toolbar>
  );
}