/**
 * @fileoverview React Component that displays when the user navigates to the main app or returns while logged in
 */

import React, {useState} from 'react';

import {
  makeStyles,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Drawer,
  Divider,
  Paper,
  Chip,
} from '@material-ui/core';

import Navigation from "../Navigation/Navigation";
import TokenCard from "../TokenCard/TokenCard";
import FilterCategoryAccordion from "../FilterCategoryAccordion/FilterCategoryAccordion";
import FilterSliderAccordion from "../FilterSliderAccordion/FilterSliderAccordion";

import tokens from "../../data/tokens.json";
import filters from "../../data/filters.json";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    border: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    background:"transparent",
    border: 'none',
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  chipWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    backgroundColor:"transparent",
    padding: theme.spacing(0.5),
    margin: '0 0 1em 0',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

/**
 * Main app page for TD Collection
 */
export default function Main() {
  const classes = useStyles();

  /*
  * For the intention of testing for Product Lab only, sample data is hard-coded below.
  * All data below should eventually instead be dynamically accessed from the token data
  */

  const [chipData, setChipData] = useState([
    { key: 0, label: 'Rarity' },
    { key: 1, label: 'Slot' },
    { key: 2, label: 'Class' },
    { key: 3, label: 'Year' },
    { key: 4, label: 'Classification' },
  ]);

  const [tokenData] = useState(tokens);

  const handleChipDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Navigation searchbar='true'/>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar key="drawerClip"/>
        <div className={classes.drawerContainer}>
          {Object.keys(filters).map((key,i) => (
            <div>
              {key==="Year" ?
                <FilterSliderAccordion
                  key={`${key}${i}`}
                  category={key}
                  filters={filters[key]}
                />
                :
                <FilterCategoryAccordion
                  key={`${key}${i}`}
                  category={key}
                  filters={filters[key].sort()}
                />
              }
              <Divider/>
            </div>
          ))}
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar key="contentClip"/>
        <Typography variant="h6">Filters</Typography>
        <Paper component="ul" elevation={0} className={classes.chipWrapper}>
          {chipData.map((data,i) => {
            return (
              <li key={`${data}${i}-chip`}>
                <Chip
                  label={data.label}
                  onDelete={handleChipDelete(data)}
                  className={classes.chip}
                />
              </li>
            );
          })}
        </Paper>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="stretch"
          spacing={3}
        >
          {Object.keys(tokenData).map((key,i) => {
            return (
              <TokenCard
                key={`${key}${i}`}
                name={`${key}`}
                token={tokenData[key]}
              />
            )
          })}
        </Grid>
      </main>
    </div>
  );
}