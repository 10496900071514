/**
 * @fileoverview React Component to display a token summary card
 */

import React, {useState} from 'react';

import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Tooltip,
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import placeholderToken from '../../images/tokens/PlaceholderToken.png'

// Sizing configuration for token cards
const cardWidth = 175;
const tokenImageMargin = 10;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: cardWidth,
    height: "100%",
    backgroundColor:"#3D4459", // minor variation from `secondary.contrastText`
  },
  tokenActionArea: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
  tokenMedia: {
    height: cardWidth-(tokenImageMargin*2),
    width: cardWidth-(tokenImageMargin*2),
    borderRadius: '50%',
    margin: tokenImageMargin,
  },
  tokenCardActions: {
    marginTop: 'auto',
  },
  tokenControls: {
    marginLeft: 'auto',
  },
  tokenSecondaryText: {
    padding: '0 0 0 8px', // Aligns with token name
  },
}));

/**
 * Token card component, displaying the token name, image, rarity, and an "add to collection" button
 *
 * @param {string} name - The JSON main key name for the token (token name).
 * @param {Object} token - JSON object of the token subkeys (i.e. `tokenData[key]`).
 */
export default function TokenCard(props) {
  const classes = useStyles();

  const [name] = useState(props.name);
  const [image] = useState(props.token.image);
  const [rarity] = useState(props.token.rarity);
  const [helpers] = useState(props.token.helpers);
  const [slot] = useState(props.token.slot);
  const [years] = useState(props.token.years);
  const [exchangePoints] = useState(props.token.exchange_points);
  const [exchangesTo] = useState(props.token.exchanges_to);
  const [sources] = useState(props.token.sources);
  const [link] = useState(props.token.link);

  /**
   * Attempt to require the related token image, and fall back to a placeholder if missing
   */
  const fallbackRequire = () => {
    try {
      return require(`../../images/tokens/${image}`);
    }
    catch (e) {
      return placeholderToken;
    }
  }

  // ToolTip only for interim token data display, remove when detail component is made
  const toolTipStr =
    <div>
      <div>helpers: {helpers}</div>
      <div>slot: {slot}</div>
      <div>years: {years}</div>
      <div>exchangePoints: {exchangePoints}</div>
      <div>exchangesTo: {exchangesTo}</div>
      <div>sources: {sources}</div>
    </div>

  return (
    <React.Fragment>
      <Grid item key={name}>
        <Tooltip title={toolTipStr}>
          <Card className={classes.root}>
            <CardActionArea
              className={classes.tokenActionArea}
              onClick={()=>window.open(link)}
            >
              <CardMedia
                className={classes.tokenMedia}
                image={fallbackRequire()}
                title={`${name}`}
              />
            </CardActionArea>
            <CardContent>
              <Typography variant="h6">
                {`${name}`}
              </Typography>
            </CardContent>
            <CardActions disableSpacing className={classes.tokenCardActions}>
              <Typography variant="body2" className={classes.tokenSecondaryText}>
                {`${rarity}`}
              </Typography>
              <IconButton aria-label="add to collection" className={classes.tokenControls}>
                <AddCircleIcon fontSize="large"/>
              </IconButton>
            </CardActions>
          </Card>
        </Tooltip>
      </Grid>
    </React.Fragment>
  );
}