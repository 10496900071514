/**
 * @fileoverview React Component that displays when a user first visits the site
 */

import React from "react";
import { Link } from "react-router-dom";

import {
  makeStyles,
  AppBar,
  Grid,
  Typography,
} from "@material-ui/core";

import backgroundImg from "../../images/TokenBackground01.jpg";

import AssignmentIcon from "@material-ui/icons/Assignment";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import ShareIcon from "@material-ui/icons/Share";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Navigation from "../Navigation/Navigation";
import FeatureCard from "../FeatureCard/FeatureCard";

const useStyles = makeStyles((theme) => ({
  section: {
    padding: "0 4em 0 4em",
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch",
    overflow: 'hidden',
  },
  link: {
    textDecoration: 'none'
  },
  bodyGrid: {
    minWidth: '100%',
    minHeight: 'calc(100vh - 64px)',
    overflow: 'hidden',
  },
  icon: {
    padding: "10px 0 0 4px",
  },
  hover: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    }
  },
  backgroundImage: {
    position: 'absolute',
    minHeight: "100vh",
    minWidth: "100%",
    top: 0,
    right: 0,
    zIndex: -999,
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundColor: '#000000',
    filter: 'brightness(35%)',
  },
}));

/**
 * Landing page for TD Collection
 */
export default function Landing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.backgroundImage}/>
      <AppBar position="static" color="transparent" elevation={0}>
        <Navigation />
      </AppBar>
      <section className={classes.section}>
        <Grid container spacing={3} className={classes.bodyGrid}>
          <Grid container item xs={12} sm={9} spacing={3}
            direction="column"
            justify="center"
            alignItems="stretch"
          >
            <Grid container item spacing={3}
              direction="row"
              justify="space-around"
            >
              <FeatureCard
                heading="Manage"
                icon={<AssignmentIcon fontSize="large"/>}
                content="Keep track of all your True Dungeon tokens"
              />
              <FeatureCard
                heading="Trade"
                icon={<CompareArrowsIcon fontSize="large"/>}
                content="Add tokens to a wish-list and find other who have those tokens up for trade"
              />
              <FeatureCard
                heading="Share"
                icon={<ShareIcon fontSize="large"/>}
                content="Show off your collection to others"
              />
              <Grid item xs={12}>
                <Typography variant="h1">TD Collection</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} spacing={3}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Link to="/tokens" className={classes.link}>
              <Typography
                color='primary'
                align='center'
                variant="h4"
                noWrap
                className={classes.hover}
              >
                GET STARTED
                <ArrowForwardIcon fontSize="large" className={classes.icon}/>
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </section>
    </React.Fragment>
  );
}