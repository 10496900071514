import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import {
  CssBaseline,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import Landing from "../Landing/Landing";
import Main from "../Main/Main";

import "./App.css";
import { siteTheme } from "../../themes";

const useStyles = makeStyles((theme) => ({
  app: {
    minWidth: '100%',
    minHeight: 'calc(100vh - 64px)',
    overflowX: 'hidden',
  },
}));

/**
 * Main app for TD Collection.
 * Handles routing and basic destination page composition.
 */
export default function App() {
  const classes = useStyles();

  const LandingPage = () => (
    <div className={classes.app}>
      <Landing />
    </div>
  );

  const MainPage = () => (
    <div>
      <Main />
    </div>
  );

  return (
    <MuiThemeProvider theme={siteTheme}>
      <CssBaseline />
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/tokens" component={MainPage} />
            {/* 404 redirect to landing */}
            <Route component={LandingPage} />
          </Switch>
        </div>
      </Router>
    </MuiThemeProvider>
  );
}