/**
 * @fileoverview React Component detailing site features for the Landing page
 */

import React, {useState} from "react";

import {
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    padding: "10px 0 0 4px",
  },
}));

/**
 * Feature card component, displaying a category, icon, and short blurb
 *
 * @param {string} heading - A word to describe the feature.
 * @param {Object} icon - A Material UI icon component to visually represent the feature.
 * @param {string} content - A description for the feature.
 */
export default function FeatureCard(props) {
  const classes = useStyles();

  const [heading] = useState(props.heading);
  const [icon] = useState(props.icon);
  const [content] = useState(props.content);

  return (
    <React.Fragment>
      <Grid item xs={12} md={4}>
        <Grid container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h4">
              <Grid container direction="row" alignItems="center" wrap="nowrap">
                <Grid item>{heading}</Grid>
                <Grid item className={classes.icon}>
                  {icon}
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">{content}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}