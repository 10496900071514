/**
 * @fileoverview React Component to display a filter category accordion using sliders
 */

import React, {useState} from 'react';

import {
  makeStyles,
  withStyles,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  Slider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 'none',
  },
  catTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  yearlessCheckbox: {
    marginLeft:'auto',
  },
  sliderWrapper: {
    display: 'flex',
    padding: '0',
    justifyContent: 'center',
  },
  slider: {
    width: '80%',
  },
}));

const YearsSlider = withStyles((theme) => ({
  valueLabel: {
    top: -20,
    '& *': {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  track: {
    height: 2,
    opacity: 0.8,
    backgroundColor: theme.palette.primary.contrastText,
  },
  rail: {
    backgroundColor: theme.palette.secondary.main,
  }
}))(Slider);

/**
 * Filter Category Accordion component (slider-style), displaying the filter category, an "all" toggle, and all available filters
 *
 * @param {string} category - The JSON main key name for the category (category name).
 * @param {Object} filters - JSON object of the category subkeys (i.e. `filterCategories[key]`).
 */
export default function FilterSliderAccordion(props) {
  const classes = useStyles();

  const [category] = useState(props.category);
  const [filters] = useState(props.filters);

  const [yearless, setYearless] = useState(false);

  const [sortedValues] = React.useState(() => {
    return sortNumberValues();
  });

  const [minValue] = React.useState(() => {
    return parseInt(sortedValues[0]);
  });

  const [maxValue] = React.useState(() => {
    return parseInt(sortedValues[sortedValues.length-1]);
  });

  const [value, setValue] = React.useState([minValue,maxValue]);

  const handleToggleYearless = () => (event) => {
    event.stopPropagation();
    setYearless(event.target.checked);
    console.log(event.target.checked);
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  function valueText(value) {
    return `${value}`;
  }

  /**
   * Sorts the filter array supplied by props,
   * removing any strings and numbering smallest to largest
   */
  function sortNumberValues() {
    let yearsOnly = filters.filter((year) => {
      return (!isNaN(parseInt(year)));
    });

    return yearsOnly.sort((a, b) => a - b);
  }

  return (
    <React.Fragment>
      <Accordion className={classes.accordion} expanded={!yearless}>
        <AccordionSummary aria-controls={`${category} filter category`}>
          <Typography className={classes.catTitle}>{category}</Typography>
          <FormControlLabel
            className={classes.yearlessCheckbox}
            control={
              <Checkbox
                defaultUnchecked
                color="primary"
                size="small"
                inputProps={{ 'aria-label': 'Yearless checkbox' }}
                onClick={handleToggleYearless()}
              />
            }
            label="Yearless"
          />
        </AccordionSummary>
        <AccordionDetails id="range-slider" className={classes.sliderWrapper}>
          <YearsSlider
            className={classes.slider}
            value={value}
            min={minValue}
            max={maxValue}
            step={1}
            marks
            valueLabelDisplay="on"
            onChange={handleSliderChange}
            disabled={yearless}
            aria-labelledby="range-slider"
            getAriaValueText={valueText}
          />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}