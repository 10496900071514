/**
 * @fileoverview React Component to display a filter category accordion
 */

import React, {useState} from 'react';

import {
  makeStyles,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 'none',
  },
  catTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  catAllCheckbox: {
    marginLeft:'auto',
  },
}));

/**
 * Filter Category Accordion component, displaying the filter category, an "all" toggle, and all available filters
 *
 * @param {string} category - The JSON main key name for the category (category name).
 * @param {Object} filters - JSON object of the category subkeys (i.e. `filterCategories[key]`).
 */
export default function FilterCategoryAccordion(props) {
  const classes = useStyles();

  const [category] = useState(props.category);
  const [filters] = useState(props.filters);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleCatAll = () => (event, isChecked) => {
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <Accordion className={classes.accordion}
        expanded={expanded === category}
        onChange={handleChange(category)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${category} filter category`}
        >
          <Typography className={classes.catTitle}>{category}</Typography>
          <FormControlLabel
            className={classes.catAllCheckbox}
            control={
              <Checkbox
                defaultChecked
                color="primary"
                size="small"
                inputProps={{ 'aria-label': `${category} All filters checkbox` }}
                onClick={handleToggleCatAll()}
              />
            }
            label="All"
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            {filters.map((value,i) => (
              <Grid item xs={6} key={`${value}${i}-${category}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      color="primary"
                      size="small"
                      inputProps={{ 'aria-label': `${value} checkbox` }}
                    />
                  }
                  label={value.charAt(0).toUpperCase() + value.slice(1)}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}